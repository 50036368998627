import {
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs } from
"@remix-run/node";
import { type TypedMetaFunction } from "remix-typedjson";
import { typedjson, useTypedLoaderData } from "remix-typedjson";
import CallToActionCard from "#app/components/callToAction.tsx";
import InvestmentCriteriaBlock from "#app/components/home/investmentCriteriaBlock.tsx";
import OurApproachBlock from "#app/components/home/ourApproachBlock.tsx";
import PartnersBlock from "#app/components/home/ourPartnersBlock.tsx";
import WhoWeAreBlock from "#app/components/home/whoWeAreBlock.tsx";
import MainHero from "#app/components/mainHero.tsx";
import sliderCSS from "#app/styles/swiper.css";
import { CRAFT_QUERY_KEYS, getPageDetails } from "#app/utils/graphql.server.ts";

import { seomaticMeta } from "#app/utils/misc.ts";
import { type Image, type PortfolioListItem } from "#types/graphql.page.ts";
import FeaturedNewsBlock from "#app/components/home/featuredNewsBlock.tsx";

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: sliderCSS }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const noCache = Object.entries(Object.fromEntries(url.searchParams)).filter(
    ([key]) => CRAFT_QUERY_KEYS.includes(key)
  ).length;
  const data = await getPageDetails(request, "home");

  return typedjson(
    {
      seo: data.seo,
      header: {
        rotatingText: data.entry.rotatingText,
        videoUrl: data.entry.videoUrl,
        title: data.entry.title,
        subtitle: data.entry.subtitle
      },
      footer: { ...data.entry.footer[0], enabled: data.entry.footerEnabled },
      whoWeAre: {
        enabled: data.entry.whoWeAreEnabled,
        text: data.entry.whoWeAreText,
        image: data.entry.whoWeAreImage,
        link: data.entry.whoWeAreLink
      },
      ourPartners: {
        enabled: data.entry.ourPartnersEnabled,
        text: data.entry.ourPartnersText,
        image: data.entry.ourPartnersImage,
        link: data.entry.ourPartnersLink
      },
      ourApproach: {
        enabled: data.entry.ourApproachEnabled,
        text: data.entry.ourApproachText,
        image: data.entry.ourApproachImage,
        link: data.entry.ourApproachLink
      },
      investmentCriteria: {
        enabled: data.entry.investmentCriteriaEnabled,
        text: data.entry.investmentCriteriaText,
        image: data.entry.investmentCriteriaImage,
        link: data.entry.investmentCriteriaLink
      },
      portfolioList: data.portfolioList,
      featuredNews: data.entry.newsFeatured[0]
    },
    {
      headers: {
        "Cache-Control": noCache ?
        "max-age=0, s-maxage=0" :
        "max-age=60, s-maxage=300"
      }
    }
  );
}

export let headers: HeadersFunction = ({ loaderHeaders }) => {
  return { "Cache-Control": loaderHeaders.get("Cache-Control") || "" };
};

export const meta: TypedMetaFunction<typeof loader> = ({ data: { seo } }) => {
  return seomaticMeta(seo);
};

export default function IndexRoute() {
  const {
    header,
    footer,
    investmentCriteria,
    whoWeAre,
    ourApproach,
    ourPartners,
    portfolioList,
    featuredNews
  } = useTypedLoaderData<typeof loader>();

  return (
    <>
      <MainHero
        subtitle={header.subtitle}
        rotatingText={header.rotatingText}
        videoUrl={header.videoUrl} />

      <main className="flex flex-col pt-4 md:space-y-14 md:pt-24">
        {whoWeAre.enabled &&
        <WhoWeAreBlock
          link={whoWeAre.link}
          img={(whoWeAre.image as Image[])}
          text={whoWeAre.text} />}


        {ourPartners.enabled &&
        <PartnersBlock
          text={ourPartners.text}
          cards={(portfolioList as PortfolioListItem[])}
          link={ourPartners.link} />}


        {ourApproach.enabled &&
        <OurApproachBlock
          link={ourApproach.link}
          text={ourApproach.text}
          img={(ourApproach.image as Image[])} />}


        {investmentCriteria.enabled &&
        <InvestmentCriteriaBlock
          link={investmentCriteria.link}
          img={(investmentCriteria.image as Image[])}
          text={investmentCriteria.text} />}


        {featuredNews && <FeaturedNewsBlock news={featuredNews} />}
      </main>
      {footer.enabled &&
      <CallToActionCard
        description={footer.description}
        title={footer.title}
        link={footer.link} />}


    </>);

}