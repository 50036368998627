import type { Image as ImageType, Link } from '#types/graphql.page.ts'
import ArrowLink from '#app/components/arrowLink.tsx'
import Image from '../image.tsx'
import WaveTexture from '../waveTexture.tsx'

export default function WhoWeAreBlock({
	link,
	img,
	text,
}: {
	img: ImageType[]
	text: string
	link: Link
}) {
	return (
		// pb-5 pt-10 md:pt-24
		<article id="who-we-are-2" className="relative ">
			<WaveTexture side="left" />
			<div className="container relative top-0.5 z-20 flex flex-col-reverse place-items-center space-y-6 space-y-reverse md:flex-row md:space-x-4 md:space-y-0">
				<div className="box-shadow-br relative mb-10 mr-10 flex h-fit flex-row md:w-1/2">
					<h2 className="side-header relative -bottom-0 rotate-180">
						{img[0].title}
					</h2>
					<Image
						noWebp={true}
						img={img[0]}
						className="aspect-1 object-contain"
					/>
				</div>
				<div className="flex w-fit flex-col space-y-[30px] md:w-1/2">
					<div
						className="text-content flex flex-col space-y-5 bg-white "
						dangerouslySetInnerHTML={{ __html: text }}
					></div>
					<ArrowLink
						className="pt-[10px]"
						url={link.url}
						type={link.type}
						text={link.text}
					/>
				</div>
			</div>
		</article>
	)
}
