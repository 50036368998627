import type { Image as ImageType, Link } from "#types/graphql.page.ts";
import TextImageOverlayBlock from "#app/components/blocks/textImageOverlayBlock.tsx";

export default function InvestmentCriteriaBlock({
  link,
  img,
  text,
}: {
  img: ImageType[];
  text: string;
  link: Link;
}) {
  // TODO: replace with the ImageOverlayText component
  return (
    <div className="md:mb-10">
      <TextImageOverlayBlock
        block={{
          __typename: "contentBlocks_textImageOverlay_BlockType",
          format: [
            { imagePosition: "left", styles: ["wave-texture", "accent-box"] },
          ],
          image: img,
          text: text,
          link: link,
          sortOrder: 0,
          subheader: "Investment Criteria",
          title: "",
          uid: "",
        }}
      />
    </div>
  );
}
