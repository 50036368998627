import type { Image as ImageType, Link } from '#types/graphql.page.ts'
import ArrowLink from '../arrowLink.tsx'
import Image from '../image.tsx'
import WaveTexture from '../waveTexture.tsx'

export default function OurApproachBlock({
	link,
	img,
	text,
}: {
	img: ImageType[]
	text: string
	link: Link
}) {
	return (
		<article
			id="trusted-partners"
			className="relative bg-green-300/5 pb-16 pt-10 md:pb-[80px] md:pt-[120px]"
		>
			<WaveTexture side="left" />
			<div className="container relative z-10 flex h-full flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
				<div className="flex flex-row items-center md:w-2/5">
					<div className="flex flex-col space-y-[30px] pr-1 ">
						<div
							className="text-content-trusted flex flex-col space-y-5 sm:px-0"
							dangerouslySetInnerHTML={{ __html: text }}
						></div>
						<ArrowLink
							className="pl-6 pt-[10px]"
							url={link.url}
							type={link.type}
							text={link.text}
						/>
					</div>
				</div>
				<div className="relative flex h-full flex-row items-center md:w-3/5">
					<div className="gray-box-bl relative mr-6 flex flex-col md:mr-0 md:flex-row-reverse">
						<h2 className="side-header-right">{img[0].title}</h2>
						<Image noWebp={true} img={img[0]} />
					</div>
				</div>
			</div>
		</article>
	)
}
